.inputRange {
  backdrop-filter: blur(10px);
  border-radius: 32px;
  width: 460px;
  height: 38px;
  padding: 18px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  position: relative;
  z-index: 2;
  background-color: var(--inputRangeBg);
  background-image: linear-gradient(to right, #4964FE, white), linear-gradient(to right, #4964FE, white);
  background-size: 100% 1px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 21px;
    border: 1px solid #4964FE;
    border-right: none;
    border-radius: 30px 0 0 30px;
  }

  &:after {
    border: 1px solid white;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
  }


  @media (max-width: 750px) {
    display: none;
  }
}

input[type="range"] {
  -webkit-appearance: none;
  background-size: 70% 100%;
  background: var(--inputRangeLine);
  border-radius: 2px 2px 0px 0px;
  height: 2px;
  width: 415px;
  cursor: pointer;
  transition: all .3s ease;

  @media (max-width: 1024px) {
    width: 350px;
  }
}

input[type="range"]:hover {
  transition: all .3s ease;
  background: #BBC5FF linear-gradient(#FEC90C, #FEC90C) no-repeat;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-image: url("../../../assets/images/heart_home.svg");
  width: 28px;
  height: 23px;
  margin-top: 4px;
  transition: all .3s ease;
  transform: scale(1);
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  background-image: url("../../../assets/images/heart_home.svg");
  width: 28px;
  height: 23px;
  margin-top: 4px;
  transition: all .3s ease;
  transform: scale(1);
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  background-image: url("../../../assets/images/heart_home.svg");
  width: 28px;
  height: 23px;
  margin-top: 4px;
  transition: all .3s ease;
  transform: scale(1);
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-image: url("../../../assets/images/heart_home.svg");
  width: 28px;
  height: 23px;
  transform: scale(1.1);
  transition: all .3s ease;
}

input[type="range"]::-moz-range-thumb:hover {
  background-image: url("../../../assets/images/heart_home.svg");
  width: 28px;
  height: 23px;
  transform: scale(1.1);
  transition: all .3s ease;
}

input[type="range"]::-ms-thumb:hover {
  background-image: url("../../../assets/images/heart_home.svg");
  width: 28px;
  height: 23px;
  transform: scale(1.1);
  transition: all .3s ease;
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  border: none;
  background: transparent;
}

input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  border: none;
  background: transparent;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  border: none;
  background: transparent;
}
