.dashboard {
  display: flex;
  max-width: var(--maxWidth);
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  padding: 0 10px 170px;
  @media (max-width: 640px) {
    padding: 0 10px 10px;
  }
  box-sizing: border-box;

  .blured {
    filter: blur(10px);
    pointer-events: none;
  }

  .learnMoreWrapper {
    position: relative;
  }

  .button.learMore {
    position: absolute;
    left: 56%;
    top: 65%;
    text-decoration: none;
    color: #566FFE;
    border: 2px solid #566FFE;
  }

  &__button {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;

    div {
      max-width: 320px;
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    width: 100%;
    background: var(--subHeaderBg);
    gap: 32px;

    &--item {
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: var(--tabsText);
      cursor: pointer;

      &.active, &:hover {
        color: var(--link-active);
      }
    }
  }

  &__data {
    //min-height: calc(100vh - var(--headerHeight));
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--neutral2);
    padding: 32px 10px 0;
    box-sizing: border-box;

    @media (max-width: 1024px) {
      padding: 40px 10px 0;
    }

    @media (max-width: 830px) {
      padding: 90px 10px 0;
    }

    @media (max-width: 450px) {
      padding: 0 10px;
    }

    &--walletIcon {
      background: #566FFE;
      padding: 30px;
      border-radius: 100%;
    }

    &__wrapperConnect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 16px;
      margin: 32px 0;
    }

    &__content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 32px;
      width: 100%;

      @media (max-width: 830px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
      }

      @media (max-width: 450px) {
        padding-top: 20px;
      }

      &--noToken{
        display: block;
      }

      &__cards {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &--left, &--right {
            width: 80% !important;
            margin-bottom: 16px;
          }
        }

        &--left, &--right {
          width: 48%;
        }
      }

      &--leftBlock {
        max-width: 32%;
        width: 100%;

        @media (max-width: 1050px) {
          max-width: 325px;
          margin: 0 auto;
        }
      }

      &--list {
        width: 100%;
        padding: 12px 4px 12px 12px;
        background: var(--dashboardFiltersBg);
        border-radius: 8px;
        height: 772px;
        @media (max-width: 640px) {
          height: 580px;
        }
        transition: all 0.3s ease;
        z-index: 10;
        box-sizing: border-box;
        position: relative;

        @media (max-width: 1050px) {
          margin-top: 30px;
        }

        &__buttons {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          gap: 16px;
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          background: var(--subHeaderCCBg);
          backdrop-filter: blur(5px);
          border-bottom-right-radius: 24px;
          border-bottom-left-radius: 24px;
          padding: 16px;
          box-sizing: border-box;
          z-index: 3;

          div {
            max-width: 164px;
          }
        }

        .dropdown {
          margin-bottom: 14px;
          z-index: 10;
        }

        &--item-wrapper {
          overflow-y: scroll;
          height: 620px;
          @media (max-width: 640px) {
            height: 450px;
          }
          padding: 12px 6px 60px 0;
          box-sizing: border-box;
        }

        &__filters {
          display: flex;
          margin-bottom: 16px;

          @media (min-width: 640px) {
            // justify-content: center;
          }

          &--item {
            cursor: pointer;
            color: var(--tabsText);
            padding: 6px 12px;
            font-weight: 700;
            font-size: 14px;
            // border-radius: 16px;

            &.active {
              background: var(--marketplaceMenuItem);
              color: #ECF1F9;
              border: 1px solid rgba(45, 70, 214, 0.2);
              backdrop-filter: blur(5px);
            }
          }
        }

        &__item {
          margin-bottom: 16px;
        }
      }

      &--form {
        border-radius: 24px;
        height: fit-content;
        transition: all .3s ease;
        width: 72%;
        max-width: 784px;

        &__noToken {
          max-width: 784px;
          width: 100%;
          margin: 0 auto;
        }

        @media (max-width: 830px) {
          display: none;
        }

        .noItems {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 18px;
        }

        &__selectedToken {
          position: relative;
          display: flex;

          @media (max-width: 830px) {
            flex-direction: row;
            padding-top: 40px;
            margin-top: 65px;
          }


          img {
            border-radius: 20px;
            max-width: 258px;
            width: 100%;
            cursor: pointer;

            @media (max-width: 830px) {
              min-width: 93px;
              min-height: 93px;
            }
          }

          &--isAnimated {
            border-radius: 16px;
          }

          &--wrapper {
            position: relative;
            align-self: center;
            background: var(--collectionPlaceholderBg);
            border-radius: 8px;

            @media (max-width: 1050px) {
              max-width: 30%;
              width: 100%;
            }

            @media (max-width: 750px) {
              max-width: 40%;
              width: 100%;
            }

            &:after {
              content: 'Closer View';
              font-family: 'DM Sans', sans-serif;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              color: #ECF1F9;
              position: absolute;
              bottom: 14px;
              left: 50%;
              right: 50%;
              transform: translateX(-50%);
              width: 114px;
              height: 34px;
              background: rgba(52, 52, 52, 0.7);
              border: 2px solid #ECF1F9;
              backdrop-filter: blur(2px);
              border-radius: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              transition: all .3s ease;
              cursor: pointer;
            }

            &:hover {
              &:after {
                opacity: 1;
                transition: all .3s ease;
              }
            }

            video {
              border-radius: 2px;
              width: 258px;
              height: 258px;
              cursor: pointer;

              @media (max-width: 1050px) {
                width: 100%;
                height: 100%;
              }
            }
          }

          &--keyTier {
            position: absolute;
            left: 14px;
            top: 14px;
            color: rgba(255, 255, 255, 0.9);
            font-weight: 700;
            font-size: 12px;
            font-family: 'Poppins';
            text-transform: uppercase;
            padding: 8px 9px;
            background: rgba(45, 70, 214, 0.4);
            border-radius: 8px;
            @media (max-width: 1050px) {
              position: static;
              text-align: center;
            }
          }

          &--info {
            display: flex;
            flex-direction: column;
            padding-left: 32px;
            font-family: 'Poppins', sans-serif;
            justify-content: center;
            flex: 1;
            position: relative;

            @media (max-width: 767px) {
              padding-left: 22px;
            }

            .button {
              margin-top: 23px;
              width: 223px;

              img {
                height: 14px;
                width: 14px;
              }
            }

            &__buttonWrap {
              display: flex;
              margin-top: 16px;
              gap: 16px;

              div {
                max-width: 240px;
              }

              @media (max-width: 1050px) {

                div {
                  margin: 0 !important;
                  max-width: 100% !important;
                }
              }

              .stakingBtn {
                margin-left: 8px;
                width: 120px;
              }
            }

            &__upgradeButton {
              max-width: 199px;
              width: 100%;

              div {
                width: 100%;
              }
            }

            &__progressWrapper {
              display: flex;
              margin-top: 15px;
              align-items: center;

              @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
              }

              &__bar {
                position: relative;
                min-width: 160px;
                width: 100%;
                height: 20px;
                background: var(--progressLineBg2);
                border: 1px solid rgba(187, 197, 255, 0.5);
                box-shadow: 0px 20px 50px rgba(254, 201, 13, 0.2);
                border-radius: 40px;
              }

              &__line {
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 40px;
                background: #BBC5FF;
                height: 100%;
                line-height: inherit;

                &--small {
                  border-radius: 100px 0 0 100px;
                }
              }

              &__info {
                font-size: 14px;
                font-weight: 400;
                position: absolute;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 460px;
                min-width: 160px;
                font-family: 'DM Sans', sans-serif;
                line-height: 16px;
                color: #2D46D6;
                opacity: 0.9;
                z-index: 2;
                @media (max-width: 1050px) {
                  max-width: 100%;
                }
              }
            }

            &__title {
              cursor: pointer;
              font-size: 23px;
              font-weight: 600;
              color: var(--title-color);
              transition: all .3s ease;

              @media (max-width: 767px) {
                font-size: 18px;
              }

              // &:after {
              //   content: var(--viewMoreImg);
              //   margin-left: 5px;
              //   position: absolute;
              //   top: 3px;
              //   transition: all .3s ease;
              // }

              &:hover {
                color: #93A3F8;
                transition: all .3s ease;

                // &:after {
                //   content: url("../../assets/images/viewMore_purple.svg");
                //   transition: all .3s ease;
                // }
              }
            }

            &__percentToOpen {
              font-size: 16px;
              font-weight: 400;
              color: var(--title-color);
              margin-top: 4px;
            }

            &__tresrPerDay {
              width: 260px;
              height: 26px;
              background: rgba(44, 155, 142, 0.1);
              border-radius: 4px;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 12px;
              color: #2C9B8E;
              display: flex;
              align-items: center;
              justify-content: center;

              &--container {
                position: fixed;
                top: -135px;
                right: 31%;
              }
            }

            &__countToNext {
              display: flex;
              align-items: center;
              justify-content: space-between;
              max-width: 300px;
              width: 100%;
              margin: 6px 0 0;

              &--margin {
                margin-top: 35px;
              }

              &--greyText {
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                line-height: 24px;
                color: var(--tokenBalanceColor);
                margin: 0 !important;
              }

              &--blackText {
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                line-height: 24px;
                color: var(--title-color);
                margin: 0 !important;
              }
            }
          }
        }
      }
    }

  }
}

[data-theme="dark"] .rewardChestModal {
  p {
    color: #ECF1F9;
  }
}

.rewardChestModal {
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: space-between;

    .button {
      width: 45%;
    }
  }
}

.openChestModal {
  display: flex;
  flex-direction: column;

  .modal__description {
    margin: 20px 0;
  }

  .button {
    img {
      width: 16px;
    }
  }

  .button.light {
    margin-top: 8px;
    color: #BBC5FF;
    border: 2px solid #BBC5FF;
  }

  &.center {
    h2, p {
      text-align: center;
    }
  }
}

.upgradeModal {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 0 0 32px;
    font-size: 32px;
    font-weight: 700;
    font-family: 'DM Sans';
    color: var(--title-color);
  }

  &__description {
    margin: 0;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    color: var(--neutral3);
    margin-bottom: 32px;

    b {
      font-weight: 500;
      color: var(--title-color);
    }

  }

  .button {
    img {
      height: 14px;
      width: 14px;
    }
  }

  .button.light {
    margin-top: 8px;
  }

  &--successfully {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      margin-top: 20px;
      font-size: 48px;
      font-weight: 700;
      font-family: 'DM Sans';
      color: var(--title-color);
    }

    &__description {
      margin: 32px 0;
      font-size: 16px;
      font-weight: 400;
      color: var(--title-color);
      font-family: 'Poppins';
    }

    .button {
      width: 100%;
    }
  }
}

.rewards__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.learMoreWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  max-width: 1192px;
  margin: 0 auto;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  &__leftBlock {
    max-width: 41%;
    width: 100%;

    @media (max-width: 850px) {
      max-width: 100%;
    }
  }

  &__rightBlock {
    max-width: 68%;
    width: 100%;

    @media (max-width: 850px) {
      max-width: 100%;
    }
  }
}

.dashboard__data__content--list--item-wrapper::-webkit-scrollbar {
  width: 3px;
}

.dashboard__data__content--list--item-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.dashboard__data__content--list--item-wrapper::-webkit-scrollbar-thumb {
  background: var(--dashboardScrollBg);
  opacity: 0.7;
  border-radius: 3px;
}

