/* :root {
    --bg-color: #FBFBFB;
    --primary-blue: #566FFE;
    --body-bg: #ECF1F9;
    --bodyBlur: blur(400px);
    --bodyBlurredBg: rgba(255, 255, 255, 0.2);
    --neutral: #909090;
    --link-notAvtive: rgba(3, 7, 16, 0.6);
    --link-active: #566FFE;
    --title-color: #081326;
    --maxWidth: 1300px;
    --headerHeight: 82px;
    --neutral2: #313131;
    --lightBtnText: #313131;
    --neutral3: #494949;
    --neutral4: #777E90;
    --border-color: #313131;
    --border-neutral: #E8E8E8;
    --timer-count: #030710;
    --neutral5: #C7C7C7;
    --tile-bg: #FBFBFB;
    --button-bg: #FDFEFF;
    --badge-blue: rgba(86, 111, 254, 0.1);
    --badge-green: rgba(44, 155, 142, 0.1);
    --badge-red: rgba(255, 104, 113, 0.1);
    --badge-gray: rgba(145, 145, 145, 0.1);
    --progress-bg: #F8F8F8;
    --progress-line-bg: #E8E8E8;
    --subtitle-color: #313131;
    --tokenBalanceColor: rgba(3, 7, 16, 0.6);
    --treasureTitle: rgba(3, 7, 16, 0.6);
    --treasureProgressBg: rgba(254, 201, 12, 0.1);
    --treasureBorderColor: rgba(254, 201, 12, 0.5);
    --treasureLineColor: #FEC90C;
    --treasureTextColor: #D66F53;
    --cardBg: #FBFBFB;
    --spanColor: #909090;
    --subtitleColor: rgba(3, 7, 16, 0.4);
    --tabMenuBgActive: rgba(3, 7, 16, 0.6);
    --tabMenuColorActive: rgba(255, 255, 255, 0.9);
    --progressLineBg: #ECF1F9;
    --progressLineBg2: rgba(187, 197, 255, 0.3);
    --marketplaceMenuItem: rgba(86, 111, 254, 0.6);
    --marketplaceTitle: #080F20;
    --marketplacePercentage: #2C9B8E;
    --marketPlaceCollection: #000000;
    --marketplaceBg: radial-gradient(102.55% 85.52% at -2.55% 14.48%, rgba(135, 179, 255, 0.2) 0%, rgba(122, 151, 255, 0.164) 25%, rgba(209, 218, 255, 0.2) 42.19%, rgba(222, 239, 255, 0.2) 65.62%, rgba(167, 195, 247, 0.2) 100%), radial-gradient(98.37% 98.37% at 92.13% 1.63%, #B3CBF8 0%, #FDFEFF 19.2%, #EBF5FF 37.51%, #D4DDFF 49.47%, #DEE7FF 71.88%, #ABB8FF 88.75%, #A7C1F7 100%);
    --marketplaceFilters: #ECF1F9;
    --marketplaceFiltersMobile: rgba(236, 241, 249, 0.15);
    --marketplaceFiltersBorder: #4964FE;
    --marketplaceFiltersTitle: #4964FE;
    --marketplaceButton: #566FFE;
    --accountBg: rgba(236, 241, 249, 0.4);
    --accountBlur: blur(50px);
    --accountName: #1e206e;
    --accountAddress: rgba(3, 7, 16, 0.6);
    --tabsText: rgba(3, 7, 16, 0.6);
    --nftItemBorder: #C2CBFF;
    --mintedBg: #2C9B8E;
    --nftItemBg: rgba(251, 251, 251, 0.4);
    --activityTableBg: rgba(251, 251, 251, 0.1);
    --activityTableBorder: #BBC5FF;
    --nftItemPercentage: rgba(3, 7, 16, 0.4);
    --searchInput: rgba(3, 7, 16, 0.1);
    --keyId: #23262F;
    --nftInfoBg: rgba(251, 251, 251, 0.4);
    --selectorBg: rgba(160, 173, 246, 0.15);
    --selectorBgItem: rgb(218, 224, 248);
    --selectorBorder: 1px solid rgba(86, 111, 254, 0.5);
    --singleNFTTableBorder: #BBC5FF;
    --metadataBg: radial-gradient(98.37% 98.37% at 92.13% 1.63%, rgba(179, 203, 248, 0.1) 0%, rgba(253, 254, 255, 0.1) 19.2%, rgba(235, 245, 255, 0.1) 37.51%, rgba(212, 221, 255, 0.1) 49.47%, rgba(222, 231, 255, 0.1) 71.88%, rgba(171, 184, 255, 0.1) 88.75%, rgba(167, 193, 247, 0.1) 100%);
    --metadataBorder: #BBC5FF;
    --metadataPercentage: rgba(3, 7, 16, 0.4);
    --dashboardFiltersBg: rgba(255, 255, 255, 0.4);
    --footerBg: linear-gradient(330.37deg, rgba(224, 235, 254, 0.88) 10.38%, rgba(224, 235, 254, 0) 91.69%);
    --foterInputBg: rgba(236, 241, 249, 0.15);
    --dashboardDropdownBorder: #BBC5FF;
    --dashboardDropdownBg: rgba(236, 241, 249, 0.15);
    --dashboardScrollBg: rgba(187, 197, 255, 0.5);
    --dashboardBlockBg: rgba(255, 255, 255, 0.5);
    --subHeaderBg: rgba(251, 251, 251, 0.5);
    --subHeaderCCBg: rgba(251, 251, 251, 0.7);
    --dashboardTabsMobile: rgba(255, 255, 255, 0.8);
    --calendarBg: rgba(236, 241, 249, 0.2);
    --durationListBg: linear-gradient(0deg, rgba(236, 241, 249, 0.7), rgba(236, 241, 249, 0.7)), radial-gradient(98.37% 98.37% at 92.13% 1.63%, rgba(179, 203, 248, 0.4) 0%, rgba(253, 254, 255, 0.4) 19.2%, rgba(251, 235, 255, 0.4) 37.51%, rgba(212, 221, 255, 0.4) 49.47%, rgba(234, 222, 255, 0.4) 71.88%, rgba(230, 209, 239, 0.4) 88.75%, rgba(167, 193, 247, 0.4) 100%);
    --modalBg: linear-gradient(0deg, rgba(236, 241, 249, 0.7), rgba(236, 241, 249, 0.7)), radial-gradient(98.37% 98.37% at 92.13% 1.63%, rgba(179, 203, 248, 0.4) 0%, rgba(253, 254, 255, 0.4) 19.2%, rgba(251, 235, 255, 0.4) 37.51%, rgba(212, 221, 255, 0.4) 49.47%, rgba(234, 222, 255, 0.4) 71.88%, rgba(230, 209, 239, 0.4) 88.75%, rgba(167, 193, 247, 0.4) 100%);
    --modalBlur: blur(17px);
    --modalBorder: rgb(204, 204, 204);
    --accountBorder: transparent;
    --accountButtonBorder: rgba(3, 7, 16, 0.6);
    --emptyTabText: #2D46D6;
    --accountPopupBg: #d2dffc;
    --accountWalletBg: rgba(255, 255, 255, 0.4);
    --landingTitle: #080F20;
    --topCollectionBg: rgba(236, 241, 249, 0.3);
    --topCollectionText: rgba(3, 7, 16, 0.4);
    --sliderBg: #BBC5FF;
    --sliderBlur: blur(5px);
    --sliderBlurredBlock: rgba(224, 226, 255, 0.3);
    --createSellBg: rgba(236, 241, 249, 0.4);
    --createSellInputText: rgba(3, 7, 16, 0.3);
    --createSellInputBorder: #C2CBFF;
    --goBackText: #4964FE;
    --filtersText: #080F20;
    --filterOptionBg: rgba(255, 255, 255, 0.5);
    --filterOptionBorder: #93A3F8;
    --filterSelected: #4964FE;
    --filterClearAll: transparent;
    --filterDropdownBg: rgba(255, 255, 255, 0.9);
    --dropdownActiveItem: rgba(255, 255, 255, 0.5);
    --tooltipBg: rgba(255, 255, 255, 0.9);
    --tooltipArrow: url("../images/tooltipArrowWhite.svg");
    --currencyInputLabel: #080F20;
    --currencyPopupBg: #d2dffc;
    --createPageName: rgba(3, 7, 16, 0.5);
    --createPageSelectBg: rgba(187, 197, 255, 0.5);
    --addMetadataBg: radial-gradient(98.37% 98.37% at 92.13% 1.63%, rgba(179, 203, 248, 0.1) 0%, rgba(253, 254, 255, 0.1) 19.2%, rgba(235, 245, 255, 0.1) 37.51%, rgba(212, 221, 255, 0.1) 49.47%, rgba(222, 231, 255, 0.1) 71.88%, rgba(171, 184, 255, 0.1) 88.75%, rgba(167, 193, 247, 0.1) 100%);
    --addMetadataBorder: #BBC5FF;
    --copyImage: url("../../assets/images/icon-copy.svg");
    --connectButtonBg: rgba(255, 255, 255, 0.4);
    --collectionPlaceholderBg: linear-gradient(90deg, rgba(187, 197, 255, 0.5) 0%, #ECF1F9 66.44%);
    --collectionPlaceholderBg2: #ECF1F9;
    --collectionPlaceholderBg3: rgba(236, 241, 249, 0.5);
    --collectionPlaceholderBg4: rgba(236, 241, 249, 0.1);
    --nftPlaceholder: linear-gradient(90deg, #ECF1F9 0%, rgba(187, 197, 255, 0.5) 51.04%, #ECF1F9 100%);
    --nftInfoBorder: #BBC5FF;
    --collectionName: #4964FE;
    --nftTabsBorder: #BBC5FF;
    --socialsBoxShadow: 0px -1px 4px rgba(73, 100, 254, 0.5), 0px 1px 4px rgba(73, 100, 254, 0.5);
    --socialsBg: #FBFBFB;
    --offersTableBg: rgba(251, 251, 251, 0.4);
    --offersTableTitleBg: rgba(187, 197, 255, 0.1);
    --titleShadow: none;
    --buttonShadow: none;
    --mintInputBg: rgba(236, 241, 249, 0.15);
    --getItemBg: linear-gradient(269.48deg, rgba(233, 238, 252, 0.27377) 0.16%, rgba(255, 255, 255, 0.3) 95.91%);
    --printItemBg: rgba(251, 251, 251, 0.15);
    --phoneCard: url("../../assets/images/phoneCardLight.svg");
    --phoneCardHover: url("../../assets/images/proneCardLightHover.svg");
    --faqItemBg: rgba(255, 255, 255, 0.5);
    --faqItemText: rgba(3, 7, 16, 0.8);
    --arrowDown: url("../../assets/images/arrowDark.svg");
    --inputRangeBg: linear-gradient(0deg, rgba(236, 241, 249, 0.7), rgba(236, 241, 249, 0.7)), radial-gradient(98.37% 98.37% at 92.13% 1.63%, rgba(179, 203, 248, 0.4) 0%, rgba(253, 254, 255, 0.4) 19.2%, rgba(251, 235, 255, 0.4) 37.51%, rgba(212, 221, 255, 0.4) 49.47%, rgba(234, 222, 255, 0.4) 71.88%, rgba(230, 209, 239, 0.4) 88.75%, rgba(167, 193, 247, 0.4) 100%);
    --inputRangeLine: #BBC5FF linear-gradient(#4964FE, #4964FE) no-repeat;
    --mintPageBg1: linear-gradient(0deg, rgba(236, 241, 249, 0.5), rgba(236, 241, 249, 0.5)), radial-gradient(98.37% 98.37% at 92.13% 1.63%, rgba(179, 203, 248, 0.1) 0%, rgba(253, 254, 255, 0.1) 19.2%, rgba(251, 235, 255, 0.1) 37.51%, rgba(212, 221, 255, 0.1) 49.47%, rgba(234, 222, 255, 0.1) 71.88%, rgba(230, 209, 239, 0.1) 88.75%, rgba(167, 193, 247, 0.1) 100%);
    --mintPageBg2: linear-gradient(0deg, rgba(236, 241, 249, 0.3), rgba(236, 241, 249, 0.3)), radial-gradient(98.37% 98.37% at 92.13% 1.63%, rgba(179, 203, 248, 0.1) 0%, rgba(253, 254, 255, 0.1) 19.2%, rgba(251, 235, 255, 0.1) 37.51%, rgba(212, 221, 255, 0.1) 49.47%, rgba(234, 222, 255, 0.1) 71.88%, rgba(230, 209, 239, 0.1) 88.75%, rgba(167, 193, 247, 0.1) 100%);
    --viewMoreImg: url("../../assets/images/viewMore_black.svg");
    --gameKeyTitle: rgba(3, 7, 16, 0.6);
    --keyItemBg: linear-gradient(118.3deg, #FFFFFF 22.36%, rgba(255, 255, 255, 0) 100%);
    --dashboardPopap: #d2dffc;
    --modalTitle:#262B92;
    --modalHeart: url("../../assets/images/heart_blue.svg");
    --modalHeartColored: url("../../assets/images/heart_colored_blue.svg");
    --modalInputBg: rgba(236, 241, 249, 0.15);
    --modalInputBorder: rgba(86, 111, 254, 0.6);
    --sellModalTabs: rgba(147, 163, 248, 0.2);
    --calendarTitle: rgba(3, 7, 16, 0.6);
    --calendarArrow: rgba(3, 7, 16, 0.6);
    --modalImgBg: linear-gradient(118.3deg, #FFFFFF 22.36%, rgba(255, 255, 255, 0) 100%);
    --modalImgShadow: none;
    --noteShadow: 0px 4px 12px rgba(73, 100, 254, 0.24);
    --noteArrow: url("../../assets/images/arrow4.svg");
} */

[data-theme="dark"], :root {
    --bg-color: #081326;
    --white: #ECF1F9;
    --text-light: var(--white);
    --secondary: #FEC90C;
    --primary-blue: #20C1FF;
    --body-bg: #081326;
    --bodyBlur: blur(900px);
    --bodyBlurredBg: rgba(8, 15, 32, 1);
    --neutral: #C7C7C7;
    --link-notAvtive: var(--white);;
    --link-active: #FFCA0E;
    --title-color: var(--white);;
    --maxWidth: 1300px;
    --headerHeight: 82px;
    --neutral2: #FBFBFB;
    --lightBtnText: #313131;
    --neutral3: #909090;
    --neutral4: #777E90;
    --border-color: var(--white);;
    --border-neutral: #313131;
    --timer-count: #FBFBFB;
    --neutral5: #313131;
    --tile-bg: #030710;
    --button-bg: #081326;
    --badge-blue: rgba(86, 111, 254, 0.3);
    --badge-green: rgba(44, 155, 142, 0.3);
    --badge-red: rgba(255, 104, 113, 0.3);
    --badge-gray: rgba(145, 145, 145, 0.3);
    --progress-bg: #313131;
    --progress-line-bg: #FBFBFB;
    --subtitle-color: #C7C7C7;
    --tokenBalanceColor: rgba(255, 255, 255, 0.7);
    --treasureTitle: rgba(255, 255, 255, 0.85);
    --treasureProgressBg: transparent;
    --treasureBorderColor: rgba(254, 201, 12, 0.5);
    --treasureLineColor: var(--secondary);
    --treasureTextColor: var(--secondary);
    --cardBg: #030710;
    --spanColor: #919191;
    --subtitleColor: rgba(255, 255, 255, 0.85);
    --tabMenuBgActive: rgba(255, 255, 255, 0.8);
    --tabMenuColorActive: rgba(3, 7, 16, 0.6);
    --progressLineBg: rgba(38, 43, 146, 0.3);
    --progressLineBg2: #030710;
    --marketplaceMenuItem: rgba(86, 111, 254, 0.6);
    --marketplaceTitle: rgba(255, 255, 255, 0.85);
    --marketplacePercentage: #07CD9D;
    --marketPlaceCollection: rgba(255, 255, 255, 0.8);
    --marketplaceBg: linear-gradient(180deg, #061033 0%, #0A1D60 16.2%, #07174F 40.23%, #081443 68.13%, #040B26 100%);
    --marketplaceFilters: rgba(7, 18, 61, 0.5);
    --marketplaceFiltersMobile: rgba(8, 15, 32, 0.15);
    --marketplaceFiltersBorder: rgba(255, 255, 255, 0.5);
    --marketplaceFiltersTitle: rgba(255, 255, 255, 0.85);
    --marketplaceButton: #C2CBFF;
    --accountBg: rgba(8, 15, 32, 0.4);
    --accountBlur: blur(20px);
    --accountName: var(--white);;
    --accountAddress: rgba(255, 255, 255, 0.9);
    --tabsText: var(--white);;
    --nftItemBorder: rgba(3, 7, 16, 0.4);
    --mintedBg: rgba(255, 255, 255, 0.4);
    --nftItemBg: rgba(251, 251, 251, 0.1);
    --activityTableBg: rgba(147, 163, 248, 0.05);
    --activityTableBorder: rgba(73, 100, 254, 0.5);
    --nftItemPercentage: rgba(255, 255, 255, 0.7);
    --searchInput: rgba(255, 255, 255, 0.7);
    --keyId: rgba(255, 255, 255, 0.9);
    --nftInfoBg: rgba(3, 7, 16, 0.4);
    --selectorBg: rgba(3, 7, 16, 0.3);
    --selectorBgItem: rgb(7, 15, 23);
    --selectorBorder: 1px solid rgba(86, 111, 254, 0.15);
    --singleNFTTableBorder: rgba(38, 43, 146, 0.7);
    --metadataBg: linear-gradient(112.75deg, rgba(17, 58, 129, 0.3) 0%, rgba(8, 19, 38, 0) 100%);
    --metadataBorder: rgba(38, 43, 146, 0.3);
    --metadataPercentage: rgba(255, 255, 255, 0.5);
    --footerBg: linear-gradient(0deg, rgba(3, 7, 16, 0.6), rgba(3, 7, 16, 0.6));
    --foterInputBg: rgba(8, 15, 32, 0.15);
    --dashboardFiltersBg: rgba(3, 7, 16, 0.5);
    --dashboardDropdownBorder: #BBC5FF;
    --dashboardDropdownBg: rgba(8, 15, 32, 0.15);
    --dashboardScrollBg: rgba(251, 251, 251, 0.3);
    --dashboardBlockBg: rgba(3, 7, 16, 0.3);
    --subHeaderBg: rgba(8, 15, 32, 1);
    --subHeaderCCBg: rgba(8, 15, 32, 0.3);
    --dashboardTabsMobile: rgba(3, 7, 16, 0.3);
    --calendarBg: rgba(8, 15, 32, 0.5);
    --durationListBg: #181540;
    --modalBg: linear-gradient(180deg, rgba(6, 16, 51, 0.5) 0%, rgba(10, 29, 96, 0.5) 16.2%, rgba(7, 23, 79, 0.5) 40.23%, rgba(8, 20, 67, 0.5) 68.13%, rgba(4, 11, 38, 0.5) 100%);
    --modalBlur: blur(30px);
    --modalBorder: rgba(153, 0, 255, 0.2);
    --accountBorder: var(--white);;
    --accountButtonBorder: rgba(3, 7, 16, 0.6);
    --emptyTabText: #A0ADF6;
    --accountPopupBg: linear-gradient(180deg, rgba(6, 16, 51, 0.3) 0%, rgba(10, 29, 96, 0.3) 16.2%, rgba(7, 23, 79, 0.3) 40.23%, rgba(8, 20, 67, 0.3) 68.13%, rgba(4, 11, 38, 0.3) 100%);
    --accountWalletBg: rgba(187, 197, 255, 0.2);
    --landingTitle: var(--white);;
    --topCollectionBg: rgba(236, 241, 249, 0.1);
    --topCollectionText: rgba(255, 255, 255, 0.8);
    --sliderBg: rgba(3, 7, 16, 0.1);
    --sliderBlur: blur(20px);
    --sliderBlurredBlock: rgba(3, 7, 16, 0.3);
    --createSellBg: rgba(3, 7, 16, 0.4);
    --createSellInputText: rgba(255, 255, 255, 0.5);
    --createSellInputBorder: #BBC5FF;
    --goBackText: #BBC5FF;
    --filtersText: rgba(255, 255, 255, 0.7);
    --filterOptionBg: rgba(73, 100, 254, 0.1);
    --filterOptionBorder: #4964FE;
    --filterSelected: #2D46D6;
    --filterClearAll: #2D46D6;
    --filterDropdownBg: #090f23;
    --dropdownActiveItem: rgba(38, 43, 146, 0.5);
    --tooltipBg: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), rgba(38, 43, 146, 0.8);
    --tooltipArrow: url("../images/tooltipArrowDark.svg");
    --currencyInputLabel: #BBC5FF;
    --currencyPopupBg: #181540;
    --createPageName: rgba(255, 255, 255, 0.85);
    --createPageSelectBg: rgba(73, 100, 254, 0.2);
    --addMetadataBg: linear-gradient(180deg, rgba(6, 16, 51, 0.1) 0%, rgba(10, 29, 96, 0.1) 16.2%, rgba(7, 23, 79, 0.1) 40.23%, rgba(8, 20, 67, 0.1) 68.13%, rgba(4, 11, 38, 0.1) 100%);
    --addMetadataBorder: rgba(38, 43, 146, 0.3);
    --copyImage: url("../../assets/images/icon-copy-white.svg");
    --connectButtonBg: rgba(187, 197, 255, 0.1);
    --collectionPlaceholderBg: linear-gradient(90deg, #030710 0%, rgba(8, 15, 32, 0.3) 100%);
    --collectionPlaceholderBg2: #080F20;
    --collectionPlaceholderBg3: rgba(8, 15, 32, 0.5);
    --collectionPlaceholderBg4: rgba(8, 15, 32, 0.1);
    --nftPlaceholder: linear-gradient(90deg, rgba(8, 15, 32, 0.7) 0%, #030710 50.52%, rgba(8, 15, 32, 0.7) 100%);
    --nftInfoBorder: rgba(3, 7, 16, 0.1);
    --collectionName: #93A3F8;
    --nftTabsBorder: #262B92;
    --socialsBoxShadow: 0px -1px 4px rgba(255, 255, 255, 0.5), 0px 1px 4px rgba(255, 255, 255, 0.5);
    --socialsBg: rgba(3, 7, 16, 0.6);
    --offersTableBg: rgba(3, 7, 16, 0.3);
    --offersTableTitleBg: rgba(3, 7, 16, 0.1);
    --titleShadow: rgba(73, 100, 254, 0.6) 1px 0 20px;
    --buttonShadow: 0px 0px 20px rgba(73, 100, 254, 0.6);
    --mintInputBg: rgba(3, 7, 16, 0.3);
    --getItemBg: linear-gradient(269.48deg, #09153D 0.16%, #081443 53.52%, #040B26 95.91%);
    --printItemBg: rgba(251, 251, 251, 0.05);
    --phoneCard: url("../../assets/images/phoneCard.svg");
    --phoneCardHover: url("../../assets/images/phoneCardHover.svg");
    --faqItemBg: rgba(3, 7, 16, 0.3);
    --faqItemText: rgba(255, 255, 255, 0.9);
    --arrowDown: url("../../assets/images/arrowWhite.svg");
    --inputRangeBg: rgba(3, 7, 16, 0.6);
    --inputRangeLine: #BBC5FF linear-gradient(#93A3F8, #93A3F8) no-repeat;
    --mintPageBg1: linear-gradient(180deg, rgba(1, 5, 18, 0.5) 0%, rgba(5, 17, 61, 0.5) 16.2%, rgba(7, 21, 72, 0.5) 40.23%, rgba(8, 20, 67, 0.5) 68.13%, rgba(1, 4, 16, 0.5) 100%);
    --mintPageBg2: linear-gradient(180deg, rgba(6, 16, 51, 0.5) 0%, rgba(10, 29, 96, 0.5) 16.2%, rgba(7, 23, 79, 0.5) 40.23%, rgba(8, 20, 67, 0.5) 68.13%, rgba(4, 11, 38, 0.5) 100%);
    --viewMoreImg: url("../../assets/images/viewMore.svg");
    --gameKeyTitle: rgba(251, 251, 251, 0.8);
    --keyItemBg: rgba(251, 251, 251, 0.1);
    --dashboardPopap: #181540;
    --modalTitle:var(--white);;
    --modalHeart: url("../../assets/images/heart_transparent.svg");
    --modalHeartColored: url("../../assets/images/heart_colored.svg");
    --modalInputBg: transparent;
    --modalInputBorder: #BBC5FF;
    --sellModalTabs: rgba(187, 197, 255, 0.15);
    --calendarTitle: #FCFCF9;
    --calendarArrow: rgba(255, 255, 255, 0.3);
    --modalImgBg: rgba(251, 251, 251, 0.1);
    --modalImgShadow: 0px 4px 12px rgba(86, 86, 86, 0.16);
    --noteShadow: 0px 0px 20px rgba(73, 100, 254, 0.6);
    --noteArrow: url("../../assets/images/arrow4_light.svg");
}

[data-theme="dark"] .ReactModal__Content {
    background: #030710;
}

[data-theme="dark"] .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
}

[data-theme="dark"] .homePage__info-button-icon {
    background-image: url("./../../assets/images/arrowRL.svg");
}

.ReactModal__Overlay {
    z-index: 10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}