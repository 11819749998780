.approveModal {
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 50px;
    filter: none;
    transition: all .3s ease;

    &--blurred{
      filter: blur(1px);
      transition: all .3s ease;
    }
  }

  &__info {
    max-width: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    p {
      text-align: center;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: -20px;
      left: 73px;
      margin-top: 20px;
      --size: 120px;
      --border: 8px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid lighten(transparent, 5%);
      border-right: 2px solid #ECF1F9;
      animation: spin 1s linear infinite;
      @media (max-width: 640px) {
        left: 26px;
      }
    }

    &--noSpinner{
      &:after {
        display: none;
      }
    }

    &--isApproved {
      &:after {
        right: 72px;
        left: auto;
        @media (max-width: 640px) {
          right: 26px;
        }
      }
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media (min-width: 800px) {
      &--note {
        position: absolute;
        top: 110px;
        right: -50px;
        background: var(--durationListBg);
        border: 1px solid #BBC5FF;
        backdrop-filter: blur(10px);
        border-radius: 4px;
        padding: 10px;
        width: 180px;
        box-sizing: border-box;
        z-index: 2;
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease;
        box-shadow: var(--noteShadow);
        color: var(--landingTitle);
        text-align: center;

        &:after {
          content: var(--noteArrow);
          position: absolute;
          top: 20px;
          left: 47px;
          transform: rotate(277deg) scale(-1, 1);
        }

        &__visible {
          visibility: visible;
          opacity: 1;
          transition: all .3s ease;
        }
      }
    }
  }

  &__background {
    background: var(--modalBg);
    backdrop-filter: var(--modalBlur);
    border-radius: 24px;
    border: 1px solid rgb(204, 204, 204);
    padding: 36px 33px;
    box-sizing: border-box;
    width: 442px;
    height: 284px;
    position: relative;
    z-index: 11;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -5px;
      background: rgba(193, 207, 255, 0.2);
      opacity: 0.2;
      backdrop-filter: blur(12px);
      border-radius: 24px;
      width: 450px;
      height: 284px;
      transform: matrix(1, 0.08, -0.02, 1, 0, 0);
      border: 1px solid #C1CFFF;
      z-index: -1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -5px;
      background: rgba(193, 207, 255, 0.2);
      opacity: 0.2;
      backdrop-filter: blur(12px);
      border-radius: 24px;
      width: 450px;
      height: 284px;
      transform: matrix(1, -0.11, 0.02, 0.99, 0, 0);
      border: 1px solid #C1CFFF;
      z-index: -2;
    }

    @media (max-width: 640px) {
      width: 350px;
      height: 284px;
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
      transform: translate(-55%, -50%);
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
