.mintCModal {
    &__subtitle {
      text-align: center;
      max-width: 384px;
      width: 100%;
      margin: 15px auto 30px;
    }
  
    &__data{
      display: flex;
      flex-direction: column;
      gap: 13px;
    }
  
    &__block{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }
  
    &__divider{
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #BBC5FF;
      width: 100%;
    }
  
    &__text{
      width: 160px;
    }
  
    &__buttons{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-top: 40px;
    }
  }
  