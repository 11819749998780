.headerBarAccountOpen__container {
  width: 100%;
  max-width: var(--maxWidth);
  margin: 0 auto;
  position: relative;
}

.headerBarAccountOpen {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  background: var(--accountPopupBg);
  border: 1px solid #BBC5FF;
  backdrop-filter: blur(30px);
  border-radius: 24px;
  padding: 24px 16px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease;

  &__visible {
    visibility: visible;
    opacity: 1;
    transition: all .3s ease;
  }
}

.headerBarAccountOpen__arrow {
  position: absolute;
  top: -13px;
  right: 42px;
  width: 300px;
  height: 30px;
}

.headerBarAccountOpen__account {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
  }
}

.headerBarAccountOpen__avatar {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  border-radius: 100%;
}

.headerBarAccountOpen__network {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--marketplaceTitle);
  margin: 0;

  &--lp {
    margin-left: -9px ;
  }
}

.headerBarAccountOpen__address {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--marketplaceTitle);

  &--copied {
    color: #2C9B8E;
  }

  &--img {
    width: 16px;
    height: 16px;
    background: var(--copyImage);
  }

  &--imgCopied {
    width: 16px;
    height: 16px;
    background-size: cover;
    background: url("../../../assets/images/check-green.svg") no-repeat center;
  }
}

.headerBarAccountOpen__address--container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.headerBarAccountOpen__address--container:hover .headerBarAccountOpen__address--img {
  background: url("../../../assets/images/icon-copy-blue.svg");
}

.headerBarAccountOpen__address--container:hover .headerBarAccountOpen__address {
  color: #2D46D6;
}


.headerBarAccountOpen__profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-top: 20px;
  }
}

.headerBarAccountOpen__wallet {
  background: var(--accountWalletBg);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    margin: 0;
  }
}

.headerBarAccountOpen__mobile {
  padding: 0 35px 35px;
  position: relative;
  //height: calc(100% - 90px);
  color: #ffffff;
  &__filters {
    &__title {
      font-style: bold;
      text-align: center;
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
}

.headerBarAccountOpen__disconnect {
  @media (max-width: 640px) {
    position: absolute;
    bottom: 0;
  }
}
