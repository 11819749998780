.treasureBoxModal__video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
  }
}

.treasureBoxModal__success {
  position: absolute;
  top: -200px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 531px;
  height: 224px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  padding: 8px 5px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;

  p {
    text-align: center;
  }
}

.treasureBoxModal__failure {
  position: absolute;
  top: -200px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 531px;
  height: 136px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;
  background: rgba(187, 197, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 16px;

  p {
    text-align: center;
  }
}

.treasureBoxModal__visible {
  visibility: visible;
  opacity: 1;
  transition: all .5s ease;
  top: 25px;
}

.treasureBoxModal__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -200px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  gap: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;

  div {
    max-width: 237px;
  }

  &--visible {
    visibility: visible;
    opacity: 1;
    transition: all .5s ease;
    bottom: 35px;
  }

}

.treasureBoxModal__socials {
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 800px) {
    justify-content: center;
  }
}

.treasureBoxModal__title {
  color: #ECF1F9;
}

.treasureBoxModal_prize-description {
  margin-left: 50px;
  margin-right: 50px;
}

.treasureBoxModal__text--yellow {
  color: #FEC90C;
}

.treasureBoxModal__text--gray {
  color: #93A3F8;
}

.treasureBoxModal__celebration {
  position: absolute;;
}

.treasureBoxModal__prize {
  height: 250px;
}