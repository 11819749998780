.homeZoneListNew {
  width: 35%;
  position: relative;

  @media (max-width: 1000px) {
    width: 90%;
  }

  &__topText {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: -57px;
    z-index: -1;

    @media (max-width: 1000px) {
      position: absolute;
      top: 0;
      left: 10px;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--goBackText);
      margin: 0;
    }

    img {
      margin-top: 13px;
      transform: rotate(-4deg);
    }

    &--zone4 {
      img{
        margin-top: 0px;   
      }
    }
  }

  &__list {
    display: flex;
    gap: 40px;
    flex-direction: column;
    width: 100%;
    margin-top: 66px;
    @media (max-width: 1000px) {
      margin-top: 20px;
    }

  }

  &__mintAll {
    margin-top: 56px;

    @media (max-width: 1000px) {
      margin: 35px auto 0;
      width: fit-content;
    }

    div {
      max-width: 311px;
      width: 311px;
    }

    &--text {
      display: flex;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      justify-content: center;
      color: var(--tabMenuBgActive);
      margin: 8px 0 0;
      @media (max-width: 1000px) {
        text-align: center;
      }
    }
  }
}
