.DashboardTresrSmartrAvaxStaking {
  position: relative;
  max-width: 376px;
  width: 100%;
  margin-top: 32px;
  padding: 24px;
  box-sizing: border-box;
  background: var(--dashboardBlockBg);
  border-radius: 8px;

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    width: 100%;

    @media (max-width: 850px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 32px auto 0;
    }
  }

  &__currency {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: var(--landingTitle);
    margin-bottom: 16px;
  }

  &__amount {
    margin: 0 0 4px 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--landingTitle);
  }

  &__text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--createPageName);
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 32px;

    div {
      max-width: 288px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &--title {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--title-color);
    }

    &--value {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--landingTitle);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 30px;

    a{
      text-decoration: none;
    }

    span {
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: var(--collectionName);
      cursor: pointer;
      text-align: center;
    }
  }
}
