.treasure-box-results__list {
  height: 315px;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 16px;
  box-sizing: border-box;
  @media (max-width: 450px) {
    height: 350px;
  }
  &--item {
    &:hover {
      background-color: black;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    background: var(--keyItemBg);
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
    height: 72px;
    transition:  all 0.4s ease-out;
    opacity: 1;

    @media (max-width: 450px) {
      height: 100px;
      align-items: normal;
    }
  }

  // .newShow {
  //   opacity: 0;
  //   height: 0;
  //   margin-top: -24px;
  // }
}

.grayscale {
  filter: grayscale(1.0);
}

.success {
  -webkit-box-shadow: inset 0px 0px 32px 6px rgba(45,255,196,0.9);
  -moz-box-shadow: inset 0px 0px 32px 6px rgba(45,255,196,0.9);
  box-shadow: inset 0px 0px 32px 6px rgba(45,255,196,0.9);
}

.failure {
  -webkit-box-shadow: inset 0px 0px 32px 6px rgba(255,46,56,0.9);
  -moz-box-shadow: inset 0px 0px 32px 6px rgba(255,46,56,0.9);
  box-shadow: inset 0px 0px 32px 6px rgba(255,46,56,0.9);
}

.upgradeAllKeysModal__wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.upgradeAllKeysModal__image {
  border-radius: 4px;
}

.upgradeAllKeysModal__name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--landingTitle);
  margin-bottom: 8px;
  @media (max-width: 450px) {
    margin-bottom: 0px;
  }

  &--wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.upgradeAllKeysModal__level {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  span {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--landingTitle);
  }
}

.upgradeAllKeysModal__wrapperRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
  @media (max-width: 450px) {
    justify-content: start;
    align-items: start;
    padding: 8px 8px 0;
  }

  span {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--currencyInputLabel);
  }
}

.upgradeAllKeysModal__total {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--landingTitle);
  }

  &--price {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--landingTitle);
  }
}

.upgradeAllKeysModal__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 16px;

  div {
    max-width: 264px;
  }
}

.upgradeAllKeysModal__list::-webkit-scrollbar {
  width: 3px;
}

.upgradeAllKeysModal__list::-webkit-scrollbar-track {
  background-color: transparent;
}

.upgradeAllKeysModal__list::-webkit-scrollbar-thumb {
  background: var(--dashboardScrollBg);
  opacity: 0.7;
  border-radius: 3px;
}




.prizes__success {
  position: absolute;
  top: 0px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 531px;
  height: 224px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  padding: 8px 5px;
  box-sizing: border-box;
  opacity: 1;
  transition: all .5s ease;

  p {
    text-align: center;
  }
}

.prizes__slider {
  .slick-current {
    div {
      height: 425px;
    }
  }
  &--item {
    width: 100%;
    height: 425px;
    background: rgba(251, 251, 251, 0.1);
    box-shadow: 0px 4px 12px rgba(86, 86, 86, 0.16);
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;
    overflow-y: hidden;
    //transition: all .3s ease;
    padding-top: 25px;

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: var(--landingTitle);
      margin: 5px 0 0;
    }

    div {
      width: 232px;
      height: 258px;
    }
  }
}

.prizes__celebration {
  position: absolute;;
}

.prizes__slider-title {
  color: #ECF1F9;
  font-size: 24px !important;
}

.prizes__slider-description {
  margin-left: 50px !important;
  margin-right: 50px !important;
  font-size: 14px !important;
}

.prizes__prize {
  height: 250px;
  margin: 20px auto;
}

.prizes__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -200px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  gap: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;

  div {
    max-width: 237px;
  }

  &--visible {
    visibility: visible;
    opacity: 1;
    transition: all .5s ease;
    bottom: -15px;
  }

}