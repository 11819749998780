.dashboardNotWhitelisted {
  &__title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px;
    text-align: center;
    color: var(--landingTitle);
  }

  &__text {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px;
    text-align: center;
    color: var(--topCollectionText);
    margin: 0 !important;
  }

  &__link {
    width: 100%;
    display: inline-flex;
    justify-content: center;
  }


  &__input {
    width: 100%;
    max-width: 462px;
    margin: 8px auto 24px;
    position: relative;

    input {
      width: 100%;
      background: var(--marketplaceFilters);
      border: 1px solid #BBC5FF;
      backdrop-filter: blur(50px);
      border-radius: 16px;
      padding: 10px 16px;
      box-sizing: border-box;
      outline: none;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--landingTitle);
    }

    input::placeholder {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--landingTitle);
    }

    img {
      position: absolute;
      right: 16px;
      top: 18.5px;
      width: fit-content;
    }
  }
}
