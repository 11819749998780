.dropdown {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    min-width: 168px;
    width: 98%;
    box-sizing: border-box;

  &__active {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #262B92;
        padding: 4px 8px 4px 16px;
        color: var(--tabsText);
        background: var(--dashboardDropdownBg);
        backdrop-filter: blur(25px);
        border-radius: 4px;
        cursor: pointer;

        span {
            margin-right: 8px;
        }

        img {
            cursor: pointer;
            padding: 13px 11px;
            transition: all 0.3s ease;

            &.opened {
                transform: rotate(180deg);
            }
        }
    }

    &__list {
        width: max-content;
        position: absolute;
        top: 50px;
        right: -2px;
        width: 100%;
        border: 1px solid #262B92;
        border-radius: 4px;
        background: var(--bg-color);
        overflow: hidden;

        &--item {
            cursor: pointer;
            padding: 12px 16px;

            &:hover {
                color: #081326;
                background: #E8E8E8;
            }
        }
    }
}
