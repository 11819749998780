.smallKeyTimer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  div{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gameKeyTitle);
    transition: all .3s ease;
  }

  &__isActive{
    div{
      color: var(--landingTitle);
      transition: all .3s ease;
    }
  }
}

.timer {
  display: flex;
  justify-content: space-evenly;
  min-width: 256px;

  &__element {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 50px;

      &--count {
          font-family: "DM Sans";
          font-size: 32px;
          font-weight: 700;
          color: var(--timer-count);
      }

      &--label {
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          color: var(--neutral);
      }
  }
}