.upgradeAllKeysModal__list {
  height: 290px;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 16px;
  box-sizing: border-box;
  @media (max-width: 450px) {
    height: 350px;
  }
  &--item {
    &:hover {
      background-color: black;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    background: var(--keyItemBg);
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
    height: 72px;
    transition:  all 0.4s ease-out;
    opacity: 1;
    @media (max-width: 450px) {
      height: 100px;
      align-items: normal;
    }
  }

  // .newShow {
  //   opacity: 0;
  //   height: 0;
  //   margin-top: -24px;
  // }
}

.upgradeAllKeysModal__wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.upgradeAllKeysModal__image {
  border-radius: 4px;
}

.upgradeAllKeysModal__name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--landingTitle);
  margin-bottom: 8px;
  @media (max-width: 450px) {
    margin-bottom: 0px;
  }

  &--wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.upgradeAllKeysModal__level {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  span {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--landingTitle);
  }
}

.upgradeAllKeysModal__wrapperRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
  @media (max-width: 450px) {
    justify-content: start;
    align-items: start;
    padding: 8px 8px 0;
  }

  span {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--currencyInputLabel);
  }
}

.upgradeAllKeysModal__total {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--landingTitle);
  }

  &--price {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--landingTitle);
  }
}

.upgradeAllKeysModal__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 16px;

  div {
    max-width: 264px;
  }
}

.upgradeAllKeysModal__list::-webkit-scrollbar {
  width: 3px;
}

.upgradeAllKeysModal__list::-webkit-scrollbar-track {
  background-color: transparent;
}

.upgradeAllKeysModal__list::-webkit-scrollbar-thumb {
  background: var(--dashboardScrollBg);
  opacity: 0.7;
  border-radius: 3px;
}
