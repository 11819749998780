@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'DM Sans', sans-serif;
    background: var(--marketplaceBg);
    margin: 0;
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    overflow-x: hidden;
}

.App {
  overflow-x: hidden;
}


.wallet-warning-banner {
  padding: 10px;
  width: 100vw;
  background-color: var(--secondary);
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wallet-warning-banner p {
  font-size: 12px;
  font-weight: normal;
}