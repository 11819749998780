.Benefits-wrapper {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 126px;
  color: var(--text-light);

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
}

.Benefits-column {
  max-width: 500px;
  height: 300px;
  margin: 0 50px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (max-width: 1000px) {
    height: auto;
    margin: 0 10px;
  }
}

.Benefit {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;

  &__topline {
    display: inline-flex;
    align-items: center;
  }

  &__subtitle {
    margin-left: 30px;
    font-weight: 200;
    font-style: italic;
  }

  &__bullet {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-image: url('/assets/images/yellow-diamond-bullet.svg');
    background-size: contain;
  }

  @media (max-width: 1000px) {
    width: 80vw;
    font-size: 18px;
    margin-bottom: 16px;

    &__bullet {
      width: 18px;
      height: 18px;
    }
  }
}