.successKeyUpgradeModal__text{
  text-align: center;
  color: var(--link-notAvtive);

  &--yellow{
    color: var(--treasureTextColor);
  }
}

.successKeyUpgradeModal__title{
  margin-bottom: 10px;
}

.successKeyUpgradeModal__image{
  margin-top: 20px !important;
}

.successKeyUpgradeModal_socials {
      display: flex;
      gap: 12px;
      justify-content: flex-end;
      align-items: flex-end;
      height: 90%;
      max-width: var(--maxWidth);
      margin: 0 auto;
      padding: 0 10px;
      z-index: 3;
      position: relative;
  
      @media (max-width: 850px) {
        justify-content: center;
        margin-top: 200px;
      }
  
      &--dots {
        width: 32px;
        height: 32px;
        background: rgba(160, 173, 246, 0.1);
        box-shadow: 0px -1px 4px rgba(255, 255, 255, 0.5), 0px 1px 4px rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(2px);
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

.successModal {
  &__image {
    background: rgba(251, 251, 251, 0.1);
    box-shadow: 0px 4px 12px rgba(86, 86, 86, 0.16);
    border-radius: 16px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;

    video {
      width: 248px;
      height: 248px;
      border-radius: 8px;
      object-fit: cover;
    }

    img{
      width: 248px;
      height: 248px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &__text {
    &--top {
      text-align: center;
      margin: 8px 0 32px;
    }

    &--bottom {
      text-align: center;
      margin: 32px 0 24px;
      color: var(--link-notAvtive);
    }
  }

  &__block {
    background: rgba(197, 206, 255, 0.1);
    box-shadow: inset -1px -2px 4px rgba(216, 222, 255, 0.5), inset 1px 2px 4px rgba(216, 222, 255, 0.5);
    border-radius: 16px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    margin-top: 32px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    div{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
