.tooltip {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.tooltip .tooltip__text {
  visibility: hidden;
  opacity: 0;
  background: var(--tooltipBg);
  border-radius: 8px;
  text-align: left;
  padding: 4px 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--landingTitle);
  width: 190px;
  // transition: all .3s ease;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -55px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
}

.tooltip__text:after {
  content: var(--tooltipArrow);
  width: 12px;
  height: 6px;
  position: absolute;
  bottom: 3px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltip__text--isBottom {
  &:after {
    bottom: 19px;
    transform: rotate(180deg);
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip__text {
  visibility: visible;
  opacity: 1;
  // transition: all .3s ease;
}
