.KeyArt {
  --slide-spacing: 8px;
  --slide-size: 30%;
  --slide-height: 365px;
  margin-top: 96px;
  position: relative;
  max-width: 100vw;

  @media (max-width: 1000px) {
    --slide-size: 290px;
    --slide-height: 290px;
    width: 100vw;
    position: relative;
    left: -6%;
  }
  
  &__viewport {
    overflow: hidden;
  }

  &__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    height: 328px;
    margin-left: calc(var(--slide-spacing) * -1);
  }

  &__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;

    &__img {
      display: block;
      height: var(--slide-height);
      width: 100%;
      object-fit: cover;
      max-width: 100%;
    }

    &__number > span {
      color: var(--secondary);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.6rem;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__dot {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    margin: 0 .75rem;

    @media (max-width: 1000px) {
      margin: 0 .25rem;
      width: 1.25rem;
      height: 1.25rem;
    }
    
    &:after {
      background: var(--white);
      border-radius: 50%;
      width: 16px;
      height: 16px;
      content: '';
    }

    &--selected:after {
      background: var(--secondary);
    }
  }

  &__dots {
    z-index: 1;
    bottom: -56px;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
      bottom: -26px;
    }
  }

  &__button {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    z-index: 1;
    color: var(--background-site);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;

    &__svg {
      width: 65%;
      height: 65%;
    }

    &:disabled {
      opacity: 0;
      cursor: inherit;
    }

    &--prev, &--next {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      
      @media (max-width: 1000px) {
        display: none;
      }
    }

    &--prev {
      transform: translateY(50%);
      rotate: 180deg;
      left: 1.6rem;
    }

    &--next {
      top: 50%;
      transform: translateY(-50%);
      right: 1.6rem;
    }
  }
}
