.singleNFTFooter {
  background: var(--footerBg);
  // border-top: 1px solid rgba(255, 255, 255, 0.1);

  &__container {
    max-width: var(--maxWidth);
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;

    @media (max-width: 1024px) {
      padding: 0 20px;
    }
  }

  &__content {
    margin-top: 40px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    border-bottom: 1px solid var(--singleNFTTableBorder);
    border-top: 6px dotted #f6cb46;
    border-bottom: 2px solid #f6cb46; 
    padding: 50px 0 50px;
    box-sizing: border-box;
    width: 100%;
    max-width: var(--maxWidth);
    gap: 35px;

    @media (max-width: 825px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 50px 0 30px;
    }

    @media (max-width: 440px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__copyright {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--metadataPercentage);
    margin: 0;
    // padding: 40px 0 56px;
  }

  &__block1 {
    display: flex;
    flex-direction: column;
    gap: 43px;
    img {
      width: 250px;
    }    

    @media (max-width: 440px) {
      flex-direction: row;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    // gap: 25px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 160px;
    flex-wrap: wrap;
    gap: 32px;
    min-width: 20%;
    @media (max-width: 825px) {
      margin-left: 20%;
    }

    @media (max-width: 520px) {
      margin-left: 5%;
    }

    @media (max-width: 440px) {
      margin-left: 0;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__link {
    text-decoration: none;
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--treasureTitle);
  }

  &__subscribe {
    max-width: 375px;
    width: 100%;

    @media (max-width: 825px) {
      align-self: center;
      max-width: 100%;
    }

    p {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--marketplaceTitle);
      margin: 0 0 8px;
    }

    &--input {
      position: relative;

      input {
        background: var(--foterInputBg);
        border: 1px solid #c2cbff;
        backdrop-filter: blur(10px);
        border-radius: 16px;
        padding: 10px 12px;
        max-width: 375px;
        width: 100%;
        outline: none;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--marketplaceTitle);
        box-sizing: border-box;
        @media (max-width: 825px) {
          max-width: 100%;
        }
      }

      input::placeholder {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--marketplaceTitle);
      }
    }

    &--arrow {
      position: absolute;
      right: 15px;
      top: 18px;
      cursor: pointer;
    }
  }
}
