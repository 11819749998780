.dashboardKeyRewards {
  background: var(--dashboardBlockBg);
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 19px 16px;
  box-sizing: border-box;
  max-height: 221px;

  @media (max-width: 850px) {
    flex-direction: column;
    max-height: 100%;
    height: fit-content;
    gap: 30px;
    max-width: 376px;
    margin: 16px auto 0;
  }

  &__isAnimated {
    border-radius: 16px;
    height: 220px;
    max-height: 220px;
  }

  &__rewards {
    max-width: 364px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    margin: 0 0 16px 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.01em;
    color: var(--landingTitle);
  }

  &__amount {
    margin: 0 0 4px 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--landingTitle);
  }

  &__text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--createPageName);
  }

  &__button {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      max-width: 288px;
    }
  }
}
