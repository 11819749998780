.customAlert {
  width: 36%;
  position: fixed;
  z-index: 100;
  right: 0px;
  top: 170px;
  height: 86px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s ease;
  background: transparent;
  opacity: 0;
  visibility: hidden;
  @media (max-width: 640px) {
    width: 100%;
  }

  &__success {
    animation: 5s linear notificationSuccess;
  }

  &__failure {
    animation: 5s linear notificationFailure;
  }

  &__info {
    animation: 5s linear notificationInfo;
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: var(--maxWidth);
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
  }

  &__content {
    display: flex;
    margin-left: 8px;
    gap: 8px;
  }

  &__image {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }

  &__text {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.9);
  }

  &__close {
    position: absolute;
    right: 9px;
    top: 3px;
    cursor: pointer;
  }
}


@keyframes notificationSuccess {
  0% {
    top: 170px;
    opacity: 1;
    visibility: visible;
  }
  1% {
    background: rgba(44, 155, 142, 0.6);
    opacity: 1;
    visibility: visible;
  }
  20% {
    top: 82px;
    background: rgba(44, 155, 142, 0.6);
    opacity: 1;
    visibility: visible;
  }
  70%{
    background: rgba(44, 155, 142, 0.6);
    opacity: 1;
    visibility: visible;
  }
  99% {
    background: transparent;
    top: 82px;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    top: 170px;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes notificationFailure {
  0% {
    top: 170px;
    opacity: 1;
    visibility: visible;
  }
  1% {
    background: rgba(181, 0, 11, 0.6);
    opacity: 1;
    visibility: visible;
  }
  20% {
    top: 82px;
    background: rgba(181, 0, 11, 0.6);
    opacity: 1;
    visibility: visible;
  }
  70%{
    background: rgba(181, 0, 11, 0.6);
    opacity: 1;
    visibility: visible;
  }
  99% {
    background: transparent;
    top: 82px;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    top: 170px;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes notificationInfo {
  0% {
    top: 170px;
    opacity: 1;
    visibility: visible;
  }
  1% {
    background: rgba(3, 13, 51, 0.6);
    opacity: 1;
    visibility: visible;
  }
  20% {
    top: 82px;
    background: rgba(3, 13, 51, 0.6);
    opacity: 1;
    visibility: visible;
  }
  70%{
    background: rgba(3, 13, 51, 0.6);
    opacity: 1;
    visibility: visible;
  }
  99% {
    background: transparent;
    top: 82px;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    top: 170px;
    opacity: 0;
    visibility: hidden;
  }
}

.customAlert__failure + .navbar{
  z-index: 2;
}

.customAlert__success + .navbar{
  z-index: 2;
}

.customAlert__info + .navbar{
  z-index: 2;
}

